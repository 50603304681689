import { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { FormLabel } from "@/components/ui/form.tsx";
import { cn } from "@/utils";
import { NumericFormat } from "react-number-format";

import type { GuaranteeResponseDto } from "@repos/rcp-dtos";

const SubGuaranteeCheckbox = ({ label, checked, onChange, disabled }) => (
	<div className="flex items-center gap-4">
		<Checkbox
			checked={checked}
			onChange={onChange}
			className="h-5 w-5 disabled:text-black disabled:opacity-100"
			disabled={disabled}
		/>
		<FormLabel className="font-normal  peer-disabled:opacity-100">
			{label}
		</FormLabel>
	</div>
);

interface BasicGuaranteeCardProps {
	guarantee: GuaranteeResponseDto;
	isLoading?: boolean;
}

export const BasicGuaranteeCard = ({ guarantee }: BasicGuaranteeCardProps) => {
	const [displayedSection, setDisplayedSection] = useState<
		"FRANCHISE" | "DESCRIPTION" | "SUBGUARANTEES"
	>(guarantee.type === "CYBER" ? "SUBGUARANTEES" : "FRANCHISE");
	return (
		<li className="flex w-full  flex-col justify-center gap-4 rounded-2xl bg-[#F0E7DC] p-6">
			<div className="flex w-full items-center gap-3  pb-2  ">
				<img src={guarantee.iconUrl} alt={guarantee.label} className="w-8" />
				<h2 className="text-lg font-bold ">{guarantee.label}</h2>
			</div>
			<div className="flex w-full cursor-pointer justify-between gap-10 border-b border-black text-lg font-bold">
				{guarantee.type === "CYBER" && (
					<span
						className={cn(
							displayedSection === "SUBGUARANTEES"
								? "border-b-2 border-primary text-primary"
								: "text-black",
						)}
						onClick={() => setDisplayedSection("SUBGUARANTEES")}
					>
						Sous garanties
					</span>
				)}

				<span
					className={cn(
						displayedSection === "FRANCHISE"
							? "border-b-2 border-primary text-primary"
							: "text-black",
					)}
					onClick={() => setDisplayedSection("FRANCHISE")}
				>
					Franchise et Limite de garantie
				</span>
				<span
					className={cn(
						displayedSection === "DESCRIPTION"
							? "border-b-2 border-primary text-primary"
							: "text-black",
					)}
					onClick={() => setDisplayedSection("DESCRIPTION")}
				>
					Description
				</span>
			</div>
			<div className="flex w-full justify-between gap-14 lg:gap-28">
				{displayedSection === "FRANCHISE" ? (
					<>
						<div className="flex flex-1  flex-col justify-center gap-2">
							<p className="font-medium">Limite de garantie</p>
							<div className="flex flex-col justify-center gap-2">
								<p className=" w-full text-nowrap rounded-xl bg-primary/40 px-6 py-5 text-center text-lg font-bold text-white lg:px-10">
									<NumericFormat
										value={guarantee.coverageLimit}
										displayType={"text"}
										thousandSeparator={" "}
										suffix={" €"}
									/>
								</p>

								{guarantee.isCoverageLimitFixed && (
									<p className="text-sm text-gray-500">Plafond fixe</p>
								)}
							</div>
						</div>
						<div className="flex  flex-1 flex-col justify-center gap-2">
							<p className="font-medium">Franchise</p>
							<p className="w-full rounded-xl bg-primary/40 px-6 py-5 text-center text-lg font-bold text-white lg:px-10 ">
								<NumericFormat
									value={guarantee.deductible}
									displayType={"text"}
									thousandSeparator={" "}
									suffix={" €"}
								/>
							</p>
						</div>
					</>
				) : (
					<div className="flex flex-1  flex-col justify-center gap-2">
						<ul className="flex flex-col gap-2">
							{guarantee.risksCovered.map((risk) => (
								<li key={risk.label} className="flex items-center gap-2">
									<SubGuaranteeCheckbox
										label={risk.label}
										disabled={!risk.isOptional}
										checked={!risk.isOptional}
										onChange={() => {}}
									/>
									{risk.addedMonthlyPremium !== null && (
										<p className="text-lg font-bold text-primary">
											{risk.addedMonthlyPremium} €/mois
										</p>
									)}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</li>
	);
};
