import { useCallback, useEffect, useState } from "react";
import { useResetStores } from "@/hooks/resetAllStores.ts";
import { useAuthStore } from "@/stores/authStore";
import { trpc } from "@/utils/trpc";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const useAuthCheck = () => {
	const [shouldRedirect, setShouldRedirect] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const resetAllStores = useResetStores();
	const [searchParams] = useSearchParams();
	const {
		lastBrokerPartnershipId,
		setPartnershipId,
		setLastBrokerPartnershipId,
		setIsFirstVisit,
		isFirstVisit,
	} = useAuthStore(
		({
			lastBrokerPartnershipId,
			setPartnershipId,
			setLastBrokerPartnershipId,
			setIsFirstVisit,
			isFirstVisit,
		}) => ({
			lastBrokerPartnershipId,
			setPartnershipId,
			setLastBrokerPartnershipId,
			setIsFirstVisit,
			isFirstVisit,
		}),
	);

	const { mutateAsync, isSuccess, isError } =
		trpc.brokers.getBroker.useMutation({
			retry: 0,
		});
	const handleFirstVisit = useCallback(
		async (partnershipId: string | null) => {
			const { success: isValidPartnershipId } = z
				.string()
				.min(30)
				.safeParse(partnershipId);

			if (partnershipId && isValidPartnershipId) {
				try {
					await mutateAsync({ brokerApiKey: partnershipId });
					setPartnershipId(partnershipId);
					if (partnershipId !== lastBrokerPartnershipId) {
						resetAllStores();
					}
					setIsFirstVisit(false);
					setLastBrokerPartnershipId(partnershipId);
				} catch (e) {
					setShouldRedirect(true);
				}
			} else {
				setShouldRedirect(true);
			}
			setIsLoading(false);
		},
		[
			lastBrokerPartnershipId,
			mutateAsync,
			resetAllStores,
			setIsFirstVisit,
			setLastBrokerPartnershipId,
			setPartnershipId,
		],
	);
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const partnershipId = searchParams.get("partnership_id");
		if (isFirstVisit) {
			handleFirstVisit(partnershipId);
		} else if (!partnershipId) {
			setIsFirstVisit(true);
		} else {
			setIsLoading(false);
		}
	}, [handleFirstVisit, isFirstVisit, searchParams, setIsFirstVisit]);

	useEffect(() => {
		if (shouldRedirect && isError) {
			window.location.href = `${import.meta.env.VITE_PUBLIC_CATALOG_URL}/#/sign-in`;
		}
	}, [isError, isSuccess, shouldRedirect]);

	return isLoading || !isSuccess;
};

export default useAuthCheck;
