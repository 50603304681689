import { useEffect } from "react";
import { ResumeItemDisplay } from "@/components/ResumeItemDisplay.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { Loader2 } from "lucide-react";

interface SummarySectionProps {
	handleReturnButton: () => void;
	nextButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement> & {
		isLoading?: boolean;
	};
	onQuerySuccess?: () => void;
}

export const SummarySection = ({
	handleReturnButton,
	nextButtonProps,
	onQuerySuccess,
}: SummarySectionProps) => {
	const navigate = useCustomFormNavigate();
	const {
		coverageSelection,
		optionalGuaranteesInputSelection,
		basicGuarantee,
	} = formStore((state) => ({
		coverageSelection: state.coverageSelection,
		optionalGuaranteesInputSelection: state.optionalGuaranteesInputSelection,
		basicGuarantee: state.basicGuarantee,
	}));
	if (!coverageSelection.turnover || !coverageSelection.principalActivityType) {
		navigate("CoverageChoice");
	}
	const pricingSimulationQuery = trpc.pricing.simulationPricing.useQuery({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		turnover: coverageSelection.turnover!,
		coverageLimit: basicGuarantee.coverageLimit || "e100000",
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		principalActivityType: coverageSelection.principalActivityType!,
		complementaryActivitiesTypes:
			coverageSelection.complementaryActivitiesTypes,
		optionalGuaranteesInputSelection: {
			selectedGuarantees: optionalGuaranteesInputSelection.selectedGuarantees,
			worldWideTurnover:
				optionalGuaranteesInputSelection.worldWideTurnover || 1,
		},
	});
	useEffect(() => {
		if (pricingSimulationQuery.isSuccess) {
			onQuerySuccess?.();
		}
	}, [pricingSimulationQuery.isSuccess]);
	return (
		<div className="fixed right-0 mt-32 flex w-full max-w-[500px] flex-col items-center gap-4 p-8 lg:mt-8 lg:max-w-[620px]">
			<div className="flex w-full flex-col gap-4 rounded-3xl bg-secondary p-10">
				<h3 className="border-b text-2xl font-bold  text-primary">
					Résumé de votre offre
				</h3>
				<ResumeItemDisplay
					title="Date d’effet"
					value={coverageSelection.desiredEffectiveDate}
					type="date"
				/>
				<ResumeItemDisplay
					title="Chiffre d’affaires"
					value={coverageSelection.turnover}
					type="currency"
				/>
				<ResumeItemDisplay
					title="Prime/mois"
					value={pricingSimulationQuery.data?.monthlyPremiumPIT || "-"}
					type="currency"
					isLoading={pricingSimulationQuery.isLoading}
					informationTooltipContent="La première cotisation comprend la prime mensuelle ainsi que les frais de dossier.
Cette somme initiale est prélevée lors de la souscription."
				/>
				<ResumeItemDisplay
					title="Prime/an"
					value={pricingSimulationQuery.data?.annualPremiumPIT || "-"}
					type="currency"
					isLoading={pricingSimulationQuery.isLoading}
					informationTooltipContent="La transaction comprend la prime annuelle ainsi que les frais de dossier.
Cette somme est prélevée lors de la souscription."
				/>
			</div>
			<div className="flex w-full justify-between">
				<Button
					className=" rounded-xl px-8 py-6"
					variant="outline"
					type="button"
					onClick={handleReturnButton}
				>
					Retour
				</Button>
				<Button
					type="submit"
					className="gap-3 rounded-xl px-8 py-6"
					{...nextButtonProps}
				>
					{nextButtonProps?.isLoading && (
						<Loader2 className="h-5 w-5 animate-spin" />
					)}
					{nextButtonProps?.children || "Suivant"}
				</Button>
			</div>
		</div>
	);
};
