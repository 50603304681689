import { useState } from "react";
import { AddressInput } from "@/components/inputs/AdressInput.tsx";
import { PhoneInput } from "@/components/inputs/PhoneInput.tsx";
import { SirenInput } from "@/components/inputs/SirenInput.tsx";
import { TextInput } from "@/components/inputs/TextInput.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";

import type { CompanyDto } from "@repos/rcp-dtos";

export const SubsriberInfoSection = () => {
	const [companyAndAddressIsManual, setCompanyAndAddressIsManual] =
		useState(false);
	const [cityAndPostalCodeIsManual, setCityAndPostalCodeIsManual] =
		useState(false);
	return (
		<div
			className={
				"col-span-7 mt-20 flex h-full flex-col  justify-center gap-6  p-16 pr-32 lg:mt-0"
			}
		>
			<div className="flex w-full flex-col  justify-evenly gap-6">
				<h1 className="text-3xl font-bold text-rcp-secondary">
					Informations de l’assuré
				</h1>
				<SirenInput
					onQueryError={() => {
						setCompanyAndAddressIsManual(true);
					}}
				/>
				<div className="flex items-center space-x-2">
					<Checkbox
						id="entreprise"
						checked={companyAndAddressIsManual}
						onClick={() =>
							setCompanyAndAddressIsManual(!companyAndAddressIsManual)
						}
					/>
					<label
						htmlFor="entreprise"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						Je ne trouve pas l&apos;entreprise
					</label>
				</div>

				<TextInput<CompanyDto>
					label="Raison sociale"
					name="companyName"
					disabled={!companyAndAddressIsManual}
					required
				/>
				{cityAndPostalCodeIsManual || !companyAndAddressIsManual ? (
					<TextInput
						label="Adresse"
						name="address"
						required
						disabled={!companyAndAddressIsManual}
					/>
				) : (
					<AddressInput<CompanyDto>
						label="Adresse"
						name="address"
						required
						disabled={!companyAndAddressIsManual}
						cityProps={{ name: "city" }}
						postalCodeProps={{ name: "postalCode" }}
					/>
				)}
				<div className="flex items-center space-x-2">
					<Checkbox
						id="address"
						onClick={() =>
							setCityAndPostalCodeIsManual(!cityAndPostalCodeIsManual)
						}
						disabled={!companyAndAddressIsManual}
					/>
					<label
						htmlFor="address"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						Je ne trouve pas l’adresse de mon entreprise
					</label>
				</div>
				<div className="flex w-full flex-row items-center justify-between gap-x-6">
					<TextInput<CompanyDto>
						className="w-1/2"
						label="Code postal"
						name="postalCode"
						required
						disabled={!cityAndPostalCodeIsManual}
					/>
					<TextInput<CompanyDto>
						className="w-1/2"
						label="Ville"
						name="city"
						required
						disabled={!cityAndPostalCodeIsManual}
					/>
				</div>
				<PhoneInput<CompanyDto> label="Téléphone" name="phoneNumber" required />
			</div>
		</div>
	);
};
