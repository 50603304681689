import type { SelectOptionType } from "@/components/inputs/SelectInput.tsx";
import type { LandingPageDto } from "@/pages/form/schemas";
import { FraisInput } from "@/components/inputs/FraisInput.tsx";
import { SelectInput } from "@/components/inputs/SelectInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Loader2 } from "lucide-react";
import { useWatch } from "react-hook-form";

interface FeesInputSectionProps {
	nextButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement> & {
		isLoading?: boolean;
	};
}

export const FeesInputSection = ({
	nextButtonProps,
}: FeesInputSectionProps) => {
	const isMonthly = useWatch({
		name: "fees.isMonthly",
	});
	return (
		<div className="fixed right-0 mt-32 flex w-full max-w-[500px] flex-col items-center gap-4 p-8 lg:mt-8 lg:max-w-[620px]">
			<div
				className="flex w-full flex-col gap-4 rounded-3xl bg-secondary p-10
			"
			>
				<h3 className="border-b text-2xl font-bold  text-primary">
					Frais Appliqués
				</h3>
				<FraisInput<LandingPageDto>
					label="Frais de gestion"
					name="fees.managementFees"
					disabled
				/>
				<FraisInput<LandingPageDto>
					label="Frais de dossier"
					name="fees.fileFees"
					disabled
				/>
				<SelectInput<LandingPageDto, SelectOptionType>
					label="Paiement mensuel"
					name="fees.isMonthly"
					options={[
						{
							label: "Oui",
							value: "true",
						},
						{
							label: "Non",
							value: "false",
						},
					]}
					disabled
				/>
				{isMonthly && (
					<FraisInput<LandingPageDto>
						label="Frais de fractionnement"
						name="fees.splittingFees"
						disabled
					/>
				)}
			</div>
			<Button
				type="submit"
				className="mr-16 w-fit gap-3 self-end rounded-xl px-8 py-6"
				{...nextButtonProps}
			>
				{nextButtonProps?.isLoading && (
					<Loader2 className="h-5 w-5 animate-spin" />
				)}
				{nextButtonProps?.children || "Suivant"}
			</Button>
		</div>
	);
};
