import type { GuaranteeChoiceDto } from "@/pages/form/schemas";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { BasicGuaranteeCard } from "@/pages/form/2/components/BasicGuaranteeCard.tsx";
import { CoverageLimitSelect } from "@/pages/form/2/components/CoverageLimitSelect.tsx";
import { OptionalGuaranteeCard } from "@/pages/form/2/components/OptionalGuaranteeCard.tsx";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { useWatch } from "react-hook-form";

import type { FullGuaranteeResponseDto } from "@repos/rcp-dtos";
import type { CoverageLimitDto } from "@repos/rcp-dtos/src/primitives";

interface GuaranteesSelectionSectionProps {
	setNextButtonIsDisabled: Dispatch<SetStateAction<boolean>>;
}

export const GuaranteesSelectionSection = ({
	setNextButtonIsDisabled,
}: GuaranteesSelectionSectionProps) => {
	const navigate = useCustomFormNavigate();
	const { turnover, principalActivityType, complementaryActivitiesTypes } =
		formStore((state) => state.coverageSelection);
	const optionalGuaranteesInputSelection = useWatch({
		name: "optionalGuaranteesInputSelection.selectedGuarantees",
	});
	const worldWideTurnover = useWatch({
		name: "optionalGuaranteesInputSelection.worldWideTurnover",
	});

	if (!turnover || !principalActivityType) {
		navigate("CoverageChoice");
	}
	const coverageLimitsValue = useWatch<GuaranteeChoiceDto>({
		name: "basicGuarantee.coverageLimit",
	});
	//Using states to store the guarantees data instead of using the query data directly to avoid re-rendering the component when the query data changes
	//because mutation date becomes undefined when the query data changes
	const [basicGuarantees, setBasicGuarantees] =
		useState<FullGuaranteeResponseDto["basicGuarantees"]>();
	const [optionalGuarantees, setOptionalGuarantees] =
		useState<FullGuaranteeResponseDto["optionalGuarantees"]>();
	const guaranteesQuery = trpc.guarantees.getAllGuarantees.useMutation({
		onSuccess: (data) => {
			setBasicGuarantees(data.basicGuarantees);
			setOptionalGuarantees(data.optionalGuarantees);
		},
		onError: () => {
			toast({
				title: "Erreur",
				description:
					"Une erreur s'est produite lors de la récupération des garanties",
				variant: "destructive",
			});
		},
	});
	const validateTurnoverMutation =
		trpc.validations.validateTurnover.useMutation({
			retry: false,
			onError: (error) => {
				toast({
					title: "Erreur",
					description: error.message,
					variant: "destructive",
				});
				setNextButtonIsDisabled(true);
			},
		});
	useEffect(() => {
		guaranteesQuery.mutate({
			pricing: {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				turnover: turnover!,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				principalActivityType: principalActivityType!,
				complementaryActivitiesTypes,
				coverageLimit: (coverageLimitsValue as CoverageLimitDto) || "e100000",
				optionalGuaranteesInputSelection: {
					selectedGuarantees: optionalGuaranteesInputSelection,
					worldWideTurnover: worldWideTurnover || 1,
				},
			},
		});
		// set the next button to disabled when input data changes
		setNextButtonIsDisabled(true);
		validateTurnoverMutation.mutate({
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			turnover: turnover!,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			principalActivityType: principalActivityType!,
			complementaryActivitiesTypes: complementaryActivitiesTypes || [],
			optionalGuaranteesInputSelection: {
				selectedGuarantees: optionalGuaranteesInputSelection,
				worldWideTurnover: worldWideTurnover || 1,
			},
		});
	}, [
		turnover,
		principalActivityType,
		complementaryActivitiesTypes,
		coverageLimitsValue,
		optionalGuaranteesInputSelection,
		worldWideTurnover,
	]);
	return (
		<div
			className={
				"col-span-7 mt-20  flex h-full max-w-[900px]  flex-col justify-center  gap-6 p-16 pr-32 lg:mt-0"
			}
		>
			<div className="garantie-de-base flex flex-col justify-center gap-12">
				<h1 className="text-3xl font-bold text-rcp-secondary">
					Garanties de base
				</h1>
				<CoverageLimitSelect />
				<ul className="basic-guarantees flex flex-col justify-center gap-4">
					{basicGuarantees?.map((guarantee) => (
						<BasicGuaranteeCard
							key={guarantee.label}
							guarantee={guarantee}
							isLoading={guaranteesQuery.isLoading}
						/>
					))}
				</ul>
				<h1 className="text-3xl font-bold text-rcp-secondary">
					Garanties optionnelles
				</h1>
				<ul className="flex flex-col justify-center gap-4">
					{optionalGuarantees?.map((guarantee) => (
						<OptionalGuaranteeCard
							key={guarantee.label}
							guarantee={guarantee}
							isLoading={guaranteesQuery.isLoading}
						/>
					))}
				</ul>
			</div>
		</div>
	);
};
