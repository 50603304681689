import { z } from "zod";

// Dtos for the third party backend API
const bankInfoSchema = z
	.object({
		periodicite: z.number(),
		mode_paiement: z.enum(["iban", "card"]),
		iban: z.string().optional(),
		bic: z.string().optional(),
		nomBanque: z.string().optional(),
		titulaireCompte: z.string().optional(),
	})
	.refine(
		(input) => {
			if (input.mode_paiement === "iban" && (!input.iban || !input.bic))
				return false;
			return true;
		},
		{
			message: "Veuillez saisir le IBAN et le BIC.",
			path: ["payment"],
		},
	);
type BankInfoDto = z.infer<typeof bankInfoSchema>;
const paymentRequestSchema = z.object({
	opportuniteRef: z.string({
		required_error: "Veuillez saisir la réference de l'opportunité.",
	}),
	infoBancaire: bankInfoSchema,
});
export type PaymentRequestDto = z.infer<typeof paymentRequestSchema>;

export const periodicitySchema = z.enum(["monthly", "yearly"], {
	required_error: "Veuillez choisir la périodicité de paiement.",
});
export type PeriodicityDto = z.infer<typeof periodicitySchema>;
// Internal Dtos
export const bankInformationIBANRequestSchema = z.object({
	periodicity: periodicitySchema,
	iban: z.string({
		required_error: "Veuillez entrer le code IBAN",
	}),
	bic: z.string({
		required_error: "Veuillez entrer le code BIC",
	}),
	bank_name: z.string({
		required_error: "Veuillez entrer le nom de la banque",
	}),
	account_holder: z.string({
		required_error: "Veuillez entrer le titulaire du compte",
	}),
});
export type BankInformationIBANRequestDto = z.infer<
	typeof bankInformationIBANRequestSchema
>;

export const PaymentIBANSchema = z.object({
	bankInformation: bankInformationIBANRequestSchema,
	subscriptionId: z.string(),
});
export type PaymentIBANDto = z.infer<typeof PaymentIBANSchema>;

export const bankInformationCardRequestSchema = z.object({
	periodicity: z.enum(["monthly", "yearly"]),
});
export type BankInformationCardRequestDto = z.infer<
	typeof bankInformationCardRequestSchema
>;

export const PaymentCardSchema = z.object({
	bankInformation: bankInformationCardRequestSchema,
	subscriptionId: z.string({
		required_error: "Veuillez saisir l'ID de la souscription.",
	}),
	partnershipId: z.string().optional(),
});
export type PaymentCardDto = z.infer<typeof PaymentCardSchema>;

export const mapIBANBankInformation = (
	oldBankInfo: BankInformationIBANRequestDto,
): BankInfoDto => {
	return {
		periodicite: oldBankInfo.periodicity === "monthly" ? 12 : 1,
		mode_paiement: "iban",
		iban: oldBankInfo.iban,
		bic: oldBankInfo.bic,
		nomBanque: oldBankInfo.bank_name,
		titulaireCompte: oldBankInfo.account_holder,
	};
};
export const mapCardBankInformation = (
	oldBankInfo: BankInformationCardRequestDto,
): BankInfoDto => {
	return {
		periodicite: oldBankInfo.periodicity === "monthly" ? 12 : 1,
		mode_paiement: "card",
	};
};

export const notificationInputSchema = z.object({
	Data: z.string(),
	Len: z.number(),
});
export type NotificationDto = z.infer<typeof notificationInputSchema>;

export const notificationRequestSchema = z.object({
	affaireRef: z.string(),
	oppotuniteRef: z.string(),
});
export type NotificationRequestDto = z.infer<typeof notificationRequestSchema>;

export const notificationResponseSchema = z.object({
	message: z.string(),
	isPaid: z.boolean(),
});
export type NotificationResponseDto = z.infer<
	typeof notificationResponseSchema
>;
