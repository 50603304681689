import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";

export const ConfirmationDeclarationPage = () => {
	const [isCertified, setIsCertified] = useState(false);
	const [acceptedConditions, setAcceptedConditions] = useState(false);
	const navigate = useCustomFormNavigate();
	const selectedGuarantees = formStore(
		(state) => state.optionalGuaranteesInputSelection.selectedGuarantees,
	).map((guarantee) => guarantee.type);
	const declarationQuery = trpc.declarations.getDeclarations.useQuery({
		guaranteeTypes: selectedGuarantees,
	});
	const excludedActivitiesQuery = trpc.declarations.getExclusions.useQuery({
		guaranteeTypes: selectedGuarantees,
	});
	const [formattedDeclarations, setFormattedDeclarations] = useState<string[]>(
		[],
	);
	const [formattedExclusions, setFormattedExclusions] = useState<string[]>([]);
	const handleNext = () => {
		navigate("CompanyInfo");
	};
	const handleReturn = () => {
		navigate("GuaranteeChoice");
	};
	useEffect(() => {
		setFormattedDeclarations(
			declarationQuery.data?.declarations
				.map((declaration) => declaration.description)
				.flat() || [],
		);
	}, [declarationQuery.data]);
	useEffect(() => {
		setFormattedExclusions(
			excludedActivitiesQuery.data?.exclusions
				.map((exclusion) => exclusion.description)
				.flat() || [],
		);
	}, [excludedActivitiesQuery.data]);
	return (
		<div className="mt-12 flex h-fit w-full flex-col items-center gap-10 p-32 lg:mt-0">
			<section className="flex w-full flex-col gap-6">
				<h3 className="text-2xl font-bold  text-primary">
					Confirmation des déclarations
				</h3>
				<ul className="list-disc space-y-1.5 rounded-2xl bg-white p-12 text-lg text-pale-black">
					{formattedDeclarations.map((condition) => (
						<li key={condition}>{condition}</li>
					))}
				</ul>
			</section>
			<section className=" flex w-full flex-col gap-6">
				<h3 className="text-2xl font-bold  text-primary">
					Ne pas exercer mon activité dans les domaines suivants
				</h3>
				<ul className="list-disc space-y-1.5 rounded-2xl bg-white p-12 text-lg text-pale-black">
					{formattedExclusions.map((condition) => (
						<li key={condition}>{condition}</li>
					))}
				</ul>
			</section>
			<section className=" flex w-full list-disc flex-col gap-6 space-y-1.5 rounded-2xl bg-white p-12 text-lg text-pale-black ">
				<div className="flex w-full items-center gap-4">
					<Checkbox
						id="certified"
						onCheckedChange={() => {
							setIsCertified(!isCertified);
						}}
					/>
					<label
						htmlFor="certified"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						Je certifie l&apos;exactitude des informations fournies ci-dessus
						dans mes déclarations sur l&apos;honneur.
					</label>
				</div>
				<div className="flex w-full items-center gap-4">
					<Checkbox
						id="conditions"
						onCheckedChange={() => {
							setAcceptedConditions(!acceptedConditions);
						}}
					/>
					<label
						htmlFor="conditions"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						Je confirme avoir pris connaissance et accepté les Conditions
						Générales (CG) ainsi que les informations sur le Produit
						d&apos;Assurance Distribué (IPID)
					</label>
				</div>
			</section>
			<div className="flex justify-between gap-10 self-end">
				<Button
					className="rounded-xl px-12 py-6"
					variant="outline"
					onClick={handleReturn}
				>
					Retour
				</Button>
				<Button
					className=" rounded-xl px-12 py-6"
					disabled={!isCertified || !acceptedConditions}
					onClick={handleNext}
				>
					Suivant
				</Button>
			</div>
		</div>
	);
};
